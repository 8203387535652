// Vanilla Js Runt Control - ONLY works above 768px
const nodes = document.querySelectorAll([
  'h1',
  'h2',
  '.display-1',
  '.display-2',
  '.display-3',
  '.display-4',
  '.display-5',
  '.display-6',
  '.lead',
  '.runt-0',
]);
for (let i = 0; i < nodes.length; i++) {
  // loop
  const mediaQuery = window.matchMedia('(min-width: 768px)');
  if (mediaQuery.matches) {
    if (!nodes[i].classList.contains('runt')) {
      nodes[i].innerHTML = nodes[i].innerHTML.replace(/ (?=[^ ]*$)/i, '&#160;');
    }
  }
}
